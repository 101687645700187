import Vue from 'vue'
const App = () => import('./App.vue')
import store from "./store"

import VueCardFormat from 'vue-credit-card-validation'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import HighchartsVue from 'highcharts-vue'
import VueTheMask from 'vue-the-mask'
import '@/assets/css/flags.css'
import '@/assets/css/symbols.css'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(HighchartsVue)
Vue.use(VueCardFormat)
Vue.use(VueTheMask)

Vue.directive('$model', {
  bind: function (el, binding, vnode) {
    el.oninput = () => (vnode.context[binding.expression] = el.value)
  }
})

console.log('Version: pro@1.5.97')

if (window.app.config.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://cd0c9a2bfc344ca7abdc1bc15d52f4b1@o1037426.ingest.sentry.io/6005505",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    tracesSampleRate: 0.1,
    release: 'pro@1.5.97'
  })
}

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
